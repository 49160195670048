import utils from "../../../utils/utils";
import Dropzone from "../../../views/DropZone.vue";
import pageheader from "../../common/pageheader";
import appsheettoolsService from "../../../api/appsheettools.service";
import { mapGetters } from "vuex";
import moment from "moment";
import XLSX from "xlsx";
export default {
  name: "servicelistupload",
  components: { Dropzone, pageheader, XLSX },
  props: [],
  data() {
    return {
      ServiceListSearch: "",
      file: "uploads/Template - Services List.xlsx",
      page: 0,
      files: [],
      showUpload: false,
      dialog: false,
      input: "servicelistupload",
      isLoading: false,
      ServiceList: [],
      headers: [
        { text: "S.No", value: "sno", width: "5px", sortable: false },
        { text: "File Name", value: "fileName" },
        { text: "Date", value: "startDate" },
        { text: "Status", value: "status", align: "center" },
        { text: "Total", value: "totalRecords", align: "center" },
        { text: "Valid", value: "validRecords", align: "center" },
        { text: "Error", value: "errorRecords", align: "center" },
        {
          text: "Actions",
          value: "view",
          align: "center",
          width: "250px",
          sortable: false
        }
      ],
      ServiceToolsItems: [],
      ServiceToolHeaders: [
        {
          text: "S.No",
          value: "sno",
          sortable: "false",
          align: "center",
          width: "100px"
        },
        { text: "", value: "errorIcon", align: "center" },
        {
          text: "Error Message",
          value: "errorMessage",
          align: "center",
          width: "150px"
        },
        {
          text: "Warning Message",
          value: "warningMessage",
          align: "center",
          width: "180px"
        },
        {
          text: "Machine Serial(VIN)",
          value: "machineSerialNumber",
          align: "center",
          width: "130px"
        },
        {
          text: "Service Type",
          value: "serviceTypeFk",
          align: "center",
          width: "100px"
        },
        {
          text: "Service Status ",
          value: "serviceStatus",
          align: "center",
          width: "100px"
        },
        {
          text: "Service Date",
          value: "serviceDate",
          align: "center",
          width: "112px"
        },
        {
          text: "TU Serial Number",
          value: "trackunitSerialNumber",
          align: "center",
          width: "110px"
        },
        {
          text: "TU Device",
          value: "trackunitDeviceFk",
          align: "center",
          width: "95px"
        },
        {
          text: "Service Description",
          value: "serviceDescription",
          align: "center",
          width: "130px"
        },
        {
          text: "Engine Hours at Install",
          value: "engineHours",
          align: "center",
          width: "130px"
        },
        {
          text: "Installer Notes",
          value: "installerNotes",
          align: "center",
          width: "100px"
        },
        {
          text: "Equipment Name",
          value: "equipmentName",
          align: "center",
          width: "100px"
        },
        { text: "Brand", value: "brand", align: "center", width: "100px" },
        { text: "Model", value: "model", align: "center", width: "100px" },
        { text: "Year", value: "year", align: "center", width: "100px" },
        { text: "Type", value: "type", align: "center", width: "100px" }
      ],
      Logsdialog: false,
      viewLogs: [],
      LogsSearch: "",
      loadingLogs: false,
      fileName: "",
      Executedialog: false,
      SelectedTool: "",
      selectedId: "",
      fileLoader: false,
      Id: "",
      GetServiceExcel: [],
      companyToken: ""
    };
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        const format = "MM-DD-YYYY";
        return moment(value).format(format);
      }
    }
  },
  computed: {
    ...mapGetters([
      "roles",
      "referenceId",
      "name",
      "customerToken",
      "customerReferenceId",
      "companyName",
      "assignedTools",
      "userId",
      "reportId"
    ])
  },
  mounted() {
    this.input = utils.getInputContent("serviceListUpload", this.roles);
    this.GetServiceList();
    this.Id = this.companyName;
    this.companyToken = this.$store.getters.customerToken;
    this.customerId = this.$store.getters.referenceId;
  },
  methods: {
    openUploadDialog() {
      this.files = [];
      this.dialog = true;
      this.showUpload = false;
    },
    closeUploadDialog() {
      this.$refs.dropzone.removeAllFiles();
      this.dialog = false;
      this.showUpload = false;
    },
    dropzoneS(file) {
      utils.fileToBase64(file).then(base64String => {
        let fileData = {
          fileName: file.name,
          fileContent: base64String.split(",")[1]
        };
        this.files.push(fileData);
        this.showUpload = true;
      });
    },
    dropzoneR(file) {
      utils.fileToBase64(file).then(base64String => {
        let filterFiles = this.files.filter(function (a) {
          return a.fileName != file.name && a.fileContent != base64String;
        });
        this.files = filterFiles;
      });
    },
    removeFiles() {
      this.$refs.dropzone.removeAllFiles();
    },
    async UploadFile() {
      let payload = {
        userId: this.userId,
        fileInfo: this.files[0]
      };
      if (this.files[0] == null) {
        this.$toast.error("Please select a file ");
      } else {
        try {
          this.selecteditem = this.selectedTitle;
          this.fileLoader = true;
          let apiResult = await appsheettoolsService.ServiceFileUpload(
            this.reportId,
            payload
          );
          this.GetServiceList();
          this.dialog = false;
          if (apiResult.isSuccess == true) {
            this.fileLoader = false;
            this.$refs.dropzone.removeAllFiles();
            this.$toast.success(apiResult.systemMessage);
            this.$toast.success("File Uploaded Successfully");
            this.GetServiceList();
          } else {
            this.$toast.error(apiResult.systemMessage);
            this.GetServiceList();
          }
        } catch (err) {
          this.fileLoader = false;
          this.closeUploadDialog();
          this.$toast.error("Unable to upload file" + err);
        }
      }
    },
    async GetServiceList() {
      this.isLoading = true;
      try {
        let response = await appsheettoolsService.CustomerServiceList(
          this.reportId
        );
        if (response) {
          this.isLoading = false;
          this.ServiceList = [];
          this.ServiceList = response.map((d, index) => ({
            ...d,
            sno: index + 1,
            index: false
          }));
        }
      } catch (err) {
        this.$toast.error("Unable to load the List" + err);
        this.isLoading = false;
      }
    },
    getColor(status) {
      if (status == "Completed") return "green";
      else if (status == "LogsCreated") return "blue";
      else if (status == "ExecutionInProgress") return "#013220";
      else if (status == "InProgress") return "orange";
      else if (status == "Failed") return "red";
      else return "black";
    },
    closepopup() {
      this.ServiceToolsItems = [];
      this.Logsdialog = false;
    },
    async ShowLogs(item) {
      this.fileName = item.fileName;
      let toolId = item.toolId;
      this.Logsdialog = true;
      try {
        this.loadingLogs = true;
        let response = await appsheettoolsService.GetViewServiceLogs(
          toolId,
          this.reportId
        );
        if (response) {
          this.loadingLogs = false;
          this.ServiceToolsItems = response.map((d, index) => ({
            ...d,
            sno: index + 1
          }));
          this.ServiceExcelResponse = response;
        }
      } catch (err) {
        this.$toast.error("Unable to Execute" + err);
        this.loadingLogs = false;
      }
    },
    showExecuteDialog(item, index) {
      this.SelectedTool = item;
      this.selectedId = index;
      this.Executedialog = true;
    },
    async ShowExecutedLogs(id, index) {
      console.log(index);
      var t = this;
      let payload = {
        customerId: this.reportId,
        userId: this.userId,
        toolId: id
      };
      this.Executedialog = false;
      try {
        let response = await appsheettoolsService.ExecuteServiceTools(payload);
        if (response.isSuccess == true) {
          this.$toast.success("Execution in progress!!");
          t.ServiceList[index - 1]["index"] = true;
          this.Executedialog = false;
        } else {
          t.ServiceList[index - 1]["index"] = false;
          this.Executedialog = false;

          this.$toast.error(response.systemMessage);
        }
      } catch (err) {
        this.Executedialog = false;
        this.$toast.error("Unable to Execute" + err);
        this.loading = false;
      }
    },
    LoadExcel() {
      this.GetServiceExcel = [];
      this.GetServiceExcel.push(["Service List Upload Logs"]);
      this.GetServiceExcel.push([]);
      this.GetServiceExcel.push([
        "Machine Serial(VIN)",
        "Service Type",
        "Service Status",
        "Service Date",
        "Trackunit Serial Number",
        "TU Device",
        "Service Description",
        "Engine Hours at Install",
        "Installer Notes",
        "Equipment Name",
        "Brand",
        "Model",
        "Year",
        "Type",
        "Error Message",
        "Warning Message"
      ]);
      var ws1 = XLSX.utils.aoa_to_sheet(this.GetServiceExcel);
      let exceldata = this.ServiceExcelResponse.map(function (item) {
        delete item.serviceDateTime;
        delete item.serviceTypeFk;
        delete item.trackunitDeviceFk;
        delete item.id;
        delete item.customerFk;
        delete item.assetFk;
        return item;
      });
      XLSX.utils.sheet_add_json(ws1, exceldata, {
        header: [
          "machineSerialNumber",
          "serviceType",
          "serviceStatus",
          "serviceDate",
          "trackunitSerialNumber",
          "trackunitDevice",
          "serviceDescription",
          "engineHours",
          "installerNotes",
          "equipmentName",
          "brand",
          "model",
          "year",
          "type",
          "errorMessage",
          "warningMessage"
        ],
        skipHeader: true,
        origin: "A4"
      });
      var wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, ws1, "Service List  Logs");
      this.GetServiceExcel = [];
      XLSX.writeFile(wb1, "Service List  Logs.xlsx");
    }
  }
};
